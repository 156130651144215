<script>
import { uuid } from "@/unit/fun.js";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      courseId: null,
      key: null,
    };
  },
  // 事件处理器
  methods: {
    // 组装第一页内容并返回object
    fun_pageData_1(data) {
      let obj = {};
      let arr = [];

      // 课程名称
      obj.courseName = data.courseName;

      // 关联偏好(可多选)
      arr = [];
      data.courseLikeList.forEach((item) => {
        arr.push(item.likeId);
      });
      obj.courseLikeIdList = arr;

      // 移动端列表图
      obj.mobileListPicture = data.mobileListPicture;

      // 移动端详情图
      obj.mobileDetailPicture = data.mobileDetailPicture;

      // 移动端商品图
      obj.mobileChiefPicture = data.mobileChiefPicture;

      // PC端列表图
      obj.pcListPicture = data.pcListPicture;

      // PC端详情图
      obj.pcDetailPicture = data.pcDetailPicture;

      // 客服二维码
      obj.customerCode = data.customerCode;

      // 课程简介
      obj.introduction = data.introduction;

      // 客服介绍
      obj.customerIntroduce = data.customerIntroduce;

      // 总课时
      obj.classHour = data.classHour;

      // 必修视频数量
      obj.compulsClassHour = data.compulsClassHour;
      
      // 课程类别
      obj.courseCategoryId = data.courseCategoryId;

      // 商品分类
      obj.codeType = String(data.codeType);

      // 商品编码
      // obj.productCode = data.productCode

      // 授课老师
      arr = [];
      data.courseTeacherList.forEach((item) => {
        arr.push(item.teacherId);
      });
      obj.courseTeacherIdList = arr;

      // 相关证书
      obj.courseRelevantCertificate = data.courseRelevantCertificateList
        ? data.courseRelevantCertificateList
        : [];
      if (obj.courseRelevantCertificate.length == 0) {
        obj.courseRelevantCertificate.push({
          uid: uuid(),
          name: "",
          picture: "",
        });
      } else {
        obj.courseRelevantCertificate.forEach((item) => {
          item.uid = uuid();
        });
      }

      // 移动端课程详情
      this.initMobileDetail = data.mobileDetail;

      // PC端课程详情
      this.initPcDetail = data.pcDetail;

      // 移动端学员须知
      this.initMobileStudentInstructions = data.mobileStudentInstructions;

      // PC端学员须知
      this.initPcStudentInstructions = data.pcStudentInstructions;

      // 移动端报名指南
      // this.initMobileRegistrationGuide = data.mobileRegistrationGuide

      // PC端报名指南
      // this.initPcRegistrationGuide = data.pcRegistrationGuide

      return obj;
    },

    // 组装第二页内容并返回object
    fun_pageData_2(data) {
      let obj = {};

      // 课程目录设置
      obj.setCatalogue = data.setCatalogue;

      // 是否为免费试看
      function funFreeTrial(datas) {
        let i = 0;
        data.freeTrialSectionList.forEach((item) => {
          if (item.sectionId == datas.sectionId) {
            i = 1;
          }
        });
        return i;
      }

      // 课程目录
      function fors(arr) {
        if(!arr) {
          return []
        }
        arr.forEach((item) => {
          if (item.childList && item.childList.length > 0) {
            item.childList = fors(item.childList);
          } else {
            item.childList = [];
          }
          item.uid = uuid();
          let array = [];
          item.teacherList.forEach((items) => {
            array.push({
              id: items.teacherId,
            });
          });
          item.teacherIdList = array;
          item.freeTrial = funFreeTrial(item);
        });
        return arr;
      }
      let list = fors(data.courseSectionList);
      obj.courseCatalogueList = list;

      // 免费试看设置
      obj.freeTrialType = String(data.freeTrialType);

      // 免费试看分钟数
      obj.freeTrialMinute = data.freeTrialMinute;

      // 课程观看设置 - 首次拖拽
      obj.firstWatchType = data.firstWatchType == "1";

      // 课程观看设置 - 再次倍速
      obj.againWatch = data.againWatch == "1";

      // 课程观看设置 - 再次拖拽
      obj.firstSpeedType = data.firstSpeedType == "1";

      // 课程观看设置 - 首次倍速
      obj.againSpeed = data.againSpeed == "1";

      // 课程观看设置 - 是否按目录顺序进行观看
      obj.watchByCatalogueOrder = data.watchByCatalogueOrder == "1";

      // 课程观看设置 - 观看条件
      if (data.watchLimitType == "3") {
        // 二者皆可
        this.viewCondition1 = true;
        this.viewCondition2 = true;
      } else if (data.watchLimitType == "1") {
        // 必须通过以下课程的考试
        this.viewCondition1 = true;
      } else if (data.watchLimitType == "2") {
        // 拥有以下课程的考试证书
        this.viewCondition2 = true;
      }
      obj.watchLimitType = data.watchLimitType;

      // 课程观看设置 - 观看条件限制
      obj.courseWatchLimitList = data.watchLimitList;

      // 课程证书设置
      obj.setCourseCertificate = String(data.setCourseCertificate);
      obj.watchCount = data.watchCount;
      obj.courseCertifId = data.courseCertifId;

      if (obj.setCourseCertificate == "1") {
        // 课程证书
        function FunSectionId(id) {
          let val = "";
          function fors(arr) {
            arr.forEach((item) => {
              if (item.childList && item.childList.length > 0) {
                fors(item.childList);
              }
              if (item.sectionId == id) {
                val = item.uid;
              }
            });
          }
          fors(obj.courseCatalogueList);
          return val;
        }
        function CertificateID(arr) {
          arr.forEach((item) => {
            item.value = FunSectionId(item.sectionId);
          });
          return arr;
        }
        obj.courseCertificateList = CertificateID(data.certificateList) || [];
      } else {
        obj.courseCertificateList = [];
      }

      return obj;
    },

    // 组装第三页内容并返回object
    fun_pageData_3(data) {
      let obj = {};
      let arr = [];

      // 开班设置
      obj.setOpenClass = String(data.setOpenClass);

      // 培训场次地点
      obj.offlineSameAddr = String(data.offlineSameAddr);

      // 开班设置 - 需要设置开班时间
      if (this.offline) {
        // 线下开课
        if (data.offlineSameAddr == 1) {
          // 在同一场次地点
          arr = [];
          let addressValue = [];
          if(data.openTimeList.length) {
            addressValue[0] = data.openTimeList[0].province;
            addressValue[1] = data.openTimeList[0].city;
            addressValue[2] = data.openTimeList[0].district;
            this.addressValue = addressValue;
            data.openTimeList.forEach((item) => {
              if (item.type == 2) {
                item.uid = uuid();
                item.addressValue = addressValue;
                arr.push(item);
              }
            });
            this.FixedPlaceTrainingList = arr;

            // 省市区详细地址
            if (obj.offlineSameAddr == 1) {
              let item = this.FixedPlaceTrainingList[0];
              this.addressValue = [item.province, item.city, item.district];
              this.ProvinceAddress = item.address;
            }
          }
        } else {
          // 不在同一场次地点
          arr = [];
          if(data.openTimeList.length) {
            data.openTimeList.forEach((item) => {
              if (item.type == 2) {
                let addressValue = [];
                item.uid = uuid();
                addressValue[0] = item.province;
                addressValue[1] = item.city;
                addressValue[2] = item.district;
                item.addressValue = addressValue;
                arr.push(item);
              }
            });
            this.NonFixedPlaceTrainingList = arr;
          }
        }
      } else {
        // 线上开课
        arr = [];
        data.openTimeList.forEach((item) => {
          if (item.type == 1) {
            item.uid = uuid();
            item.disabled = !!Number(item.openTimeId);
            arr.push(item);
          }
        });
        obj.courseOpenTimeList = arr;
      }

      // 课程有效期设置 - 固定期限/长期
      obj.validDayType = data.validDayType;

      // 课程有效期设置 - 天数
      if (data.validDayType == "1") {
        obj.validDays = data.validDays;
      }

      // 课程有效期设置 - 有效期开始计算节点
      obj.validStartType = String(data.validStartType);

      // 课程有效期设置 - 开始做第n节小节自测起
      if (data.validStartType == "1" || data.validStartType == "5") {
        obj.validStartSectionId = data.validStartSectionId;
      }

      // 课程有效期 章节回显
      let _this = this;
      if (data.validStartType == "5") {
        function fors(arr) {
          arr.forEach((item) => {
            if (item.childList && item.childList.length > 0) {
              fors(item.childList);
            }
            if (item.sectionId == data.validStartSectionId) {
              _this.validStartSectionName = item.sectionName;
            }
            // _this.validStartSectionName = '11'
          });
        }
        fors(data.courseSectionList);
      }

      // 课程延期设置
      obj.setDelay = String(data.setDelay);

      // 课程延期设置 - 需要设置课程延期
      if (data.setDelay == "1") {
        obj.courseDelayList = data.delayList || [];
      } else {
        obj.courseDelayList = [];
      }

      // 课程考试设置
      obj.setExam = String(data.setExam);

      // 课程考试设置 - 需要考试
      if (data.setExam == "1") {
        obj.examCertifTempId = data.examCertifTempId;
      }

      // 小节自测设置
      obj.setSectionTest = String(data.setSectionTest);

      // 小节自测设置 - 有小节自测
      // if(data.setSectionTest == '1'){
      obj.sectionTestCorrectRate = data.sectionTestCorrectRate;
      // }

      return obj;
    },

    // 组装第四页内容并返回object
    fun_pageData_4(data) {
      let obj = {};
      let arr = [];

      // 所属商品类别
      obj.productCategoryIdList = data.productCategoryIdList;

      // 商品价格设置 - 市场价(原价)
      obj.originalPrice = data.originalPrice;

      // 商品价格设置 - 市场价是否在终端展示
      obj.setOriginal = data.setOriginal ? "1" : "0";

      // 商品价格设置 - 优惠价
      obj.couponPrice = data.couponPrice;

      // 机构购买设置 - 机构购买人数限制
      obj.organizationPrice = data.organizationPrice;

      // 机构购买设置 - 机构价
      obj.numberPeople = data.numberPeople;

      // 机构购买设置 - 允许兑换券形式购买
      obj.exchange = String(data.exchange);

      // 关联商品设置
      obj.setRelevantProduct = String(data.setRelevantProduct);

      // 关联商品
      if (data.setRelevantProduct == "1") {
        arr = [];
        data.relevantProduct.forEach((item) => {
          if (item.type == 1) {
            item.uid = uuid();
            arr.push(item);
          }
        });
        this.setRelevantProductList = arr;
      }

      // 赠品设置
      obj.setRelevantGift = String(data.setRelevantGift);

      // 关联赠品
      if (data.setRelevantGift == "1") {
        arr = [];
        data.relevantProduct.forEach((item) => {
          if (item.type == 2) {
            item.uid = uuid();
            arr.push(item);
          }
        });
        this.setRelevantGiftList = arr;
      }

      // 是否有购买限制条件
      obj.setBuyLimit = String(data.setBuyLimit);

      // 购买限制条件 1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
      if (data.buyLimitType == 3) {
        this.setBuyLimitType1 = true;
        this.setBuyLimitType2 = true;
      } else if (data.buyLimitType == 1) {
        this.setBuyLimitType1 = true;
      } else if (data.buyLimitType == 2) {
        this.setBuyLimitType2 = true;
      }

      // 限制课程
      if (!!data.buyLimitCourseList && data.buyLimitCourseList.length > 0) {
        arr = [];
        data.buyLimitCourseList.forEach((item) => {
          item.uid = uuid();
          item.courseId = item.haveCourseId;
          arr.push(item);
        });
        this.SelectCourseList = arr;
      }

      // 提交证明提示语
      obj.buyPromptContent = data.buyPromptContent;

      // 用户需要完成身份认证后才可购买
      obj.userAuthentication = String(data.userAuthentication);

      // 商品上架设置
      obj.groundSet = String(data.groundSet);

      // 商品上架设置 - 设置上架时间
      obj.groundTime = data.groundTime;

      return obj;
    },

    // 重组表单内容
    fitFormData(data) {
      // 是否是线下课程
      this.offline = data.courseCategoryOffline == 1;

      let obj = {
        // 基础设置
        ...this.fun_pageData_1(data),
        // 视频管理
        ...(!this.offline ? this.fun_pageData_2(data) : this.fun_pageData_2(data)),
        // 特殊设置
        ...this.fun_pageData_3(data),
        // 购买设置
        ...this.fun_pageData_4(data),
      };
      this.$set(this, "formData", obj);

      // 章节是否有小节自测
      this.SectionTestLength = this.returnSectionTestLength();

      this.CategorySelectList?.forEach((item) => {
        if (
          item.courseCategoryId == this.formData.courseCategoryId &&
          item.free == 1
        ) {
          this.courseFree = 1;
        }
      });
    },

    // 编辑初始化
    initEdit() {
      if (this.$route.query.state != "2") {
        return false;
      }
      let key = (this.key = "EditInit");
      this.loadingBtn = true;
      this.$message.loading({ content: "正在查询详情...", key, duration: 0 });
      this.$ajax({
        url: `/hxclass-management/course/${this.$route.query.courseId}`,
      })
        .then((res) => {
          if (res.code == "200" && res.success) {
            this.userStudy = res.data.userStudy;
            this.fitFormData(res.data);
            setTimeout(() => {
              this.$message.success({ content: "查询成功!", key, duration: 1 });
              this.loadingBtn = false;
            }, 1000);
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.warning(err.pageName + "：" + err.msg);
        });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.initEdit();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>
