<script>
import StepBar from '@/components/StepBar.vue'
import UE from "@/components/UE.vue"
import methods from './methods.vue'
import save from './save.vue'
import storage from './storage.vue'
import edit from './edit.vue'
import Modal from './Modal.vue'
import ModalProduct from './Modal_product.vue'
import Province from '@/components/provinceCity'
import LeadingIn from "@/components/leadingIn.vue"
const columns = [
  {
    title: "章节目录",
    dataIndex: "sectionName",
    key: "sectionName",
  },
  {
    title: "主讲人",
    align: "center",
    dataIndex: "teacherIdList",
    scopedSlots: { customRender: "teacherIdList" },
  },
  {
    title: "时长",
    align: "center",
    dataIndex: "duration",
    scopedSlots: { customRender: "duration" },
  },
  {
    title: "终端展示排序",
    align: "center",
    dataIndex: "show",
    scopedSlots: { customRender: "show" },
  },
  {
    title: "目录操作",
    align: "center",
    dataIndex: "Directory",
    scopedSlots: { customRender: "Directory" },
  },
  {
    title: "视频操作",
    align: "center",
    dataIndex: "videoFileId",
    scopedSlots: { customRender: "videoFileId" },
  },
  {
    title: "小节自测操作",
    align: "center",
    dataIndex: "SelfTest",
    scopedSlots: { customRender: "SelfTest" },
  },
];
const columns1 = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "班级名称",
    align:'center',
    dataIndex: "className"
  },
  {
    title: "开班时间",
    align:'center',
    dataIndex: "startTime"
  },
  {
    title: "结班时间",
    align:'center',
    dataIndex: "endTime"
  },
  {
    title: "名额",
    align:'center',
    dataIndex: "number"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
const columns2 = [
  {
    title: "班级名称",
    align:'center',
    dataIndex: "className"
  },
  {
    title: "开班时间",
    align:'center',
    dataIndex: "startTime"
  },
  {
    title: "结班时间",
    align:'center',
    dataIndex: "endTime"
  },
  {
    title: "名额",
    align:'center',
    dataIndex: "number"
  },
  {
    title: "操作",
    align:'center',
    scopedSlots: { customRender: "operation" }
  }
];
const columns3 = [
  {
    title: "班级名称",
    align:'center',
    dataIndex: "className"
  },
  {
    title: "开班时间",
    align:'center',
    dataIndex: "startTime"
  },
  {
    title: "结班时间",
    align:'center',
    dataIndex: "endTime"
  },
  {
    title: "名额",
    align:'center',
    dataIndex: "number"
  },
  {
    title: "培训地点",
    align:'center',
    dataIndex: "Province"
  },
  {
    title: "操作",
    align:'center',
    scopedSlots: { customRender: "operation" }
  }
];
const columns4 = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "商品名称",
    align:'center',
    dataIndex: "productName"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
const columns5 = [
  {
    title: "章节目录",
    dataIndex: "sectionName",
    key: "sectionName",
  },
  {
    title: "主讲人",
    align: "center",
    dataIndex: "teacherIdList",
    scopedSlots: { customRender: "teacherIdList" },
  },
  {
    title: "时长",
    align: "center",
    dataIndex: "duration",
    scopedSlots: { customRender: "duration" },
  },
  {
    title: "小节自测操作",
    align: "center",
    dataIndex: "SelfTest",
    scopedSlots: { customRender: "SelfTest" },
  }
];
const columnsss = [
  {
    title: "章节目录",
    dataIndex: "sectionName",
    key: "sectionName",
  },
  {
    title: "主讲人",
    align: "center",
    dataIndex: "teacherIdList",
    scopedSlots: { customRender: "teacherIdList" },
  },
  {
    title: "时长",
    align: "center",
    dataIndex: "duration",
    scopedSlots: { customRender: "duration" },
  },
  {
    title: "小节自测操作",
    align: "center",
    dataIndex: "SelfTest",
    scopedSlots: { customRender: "SelfTest" },
  }
];
export default {
  mixins: [ methods, save, edit, storage ],
  // 可用组件的哈希表
  components: { StepBar, UE, Modal, ModalProduct, Province, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      uid: null,
      userStudy: '0', // 是否有学员开始学习1.是 0.否
      file: {},
      courseRelevantCertificateList: [], // 多图的地址，存放在obj.url里面
      columns,
      columns1,
      columns2,
      columns3,
      columns4,
      columns5,
      tableData:[], // 列表数据
      tabIndex: 0, // 默认所在页
      initMobileDetail: '',
      initPcDetail: '',
      initMobileStudentInstructions: '',
      initPcStudentInstructions: '',
      initMobileRegistrationGuide: '',
      initPcRegistrationGuide: '',
      ueGetText: '',
      loading:false, // 列表加载
      loadingBtn:false, // 按钮加载
      uploadLoading:false, // 文件上传
      uploadLoading2:false, // 文件上传
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 5, // 页数
      setBuyLimitType1: false, // 在平台购买以下课程方可购买
      setBuyLimitType2: false, // 人工审核相关资质通过后可购买
      // setBuyLimitType3: false, // 用户需要完成身份认证才可购买
      offline: false, // 是否线下课程
      ProvinceAddress: '', // 详细地址
      importConfirmation: false, // 导入确认
      courseFree: 0,
      SectionTestLength: 0,
      // 弹框
      columnsss,
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {},
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>
