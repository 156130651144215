var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"选择题目","width":"900px"},on:{"ok":function($event){return _vm.onSave()}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"left"},[_c('a-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入题干内容"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"right"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.pageNumber=1;_vm.getList()}}},[_vm._v("搜索")])],1)]),_c('div',[_c('a-table',{staticClass:"table-template",attrs:{"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },"rowKey":function (item){ return item.libraryId; },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loading,"pagination":{
        total:_vm.total,
        current:_vm.pageNumber,  
        defaultPageSize:_vm.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return ("共" + total + "条")}}},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(e, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"type",fn:function(item){return [(item == 1)?_c('span',[_vm._v("单选")]):(item == 2)?_c('span',[_vm._v("多选")]):(item == 3)?_c('span',[_vm._v("判断")]):_vm._e()]}},{key:"difficulty",fn:function(item){return [(item == 1)?_c('span',[_vm._v("简单")]):(item == 2)?_c('span',[_vm._v("一般")]):(item == 3)?_c('span',[_vm._v("困难")]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }