<template>
  <a-modal v-model="visible" title="选择题目" width="900px" @ok="onSave()">
    <div class="title">
      <div class="left">
        <a-input style="width:240px;" v-model="name" placeholder="请输入题干内容"/>
      </div>
      <div class="right">
        <a-button type="primary" @click="pageNumber=1;getList()">搜索</a-button>
      </div>
    </div>
    <div>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange
        }"
        class="table-template"
        :rowKey="item=>item.libraryId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="e, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 1.单选 2.多选 3.判断 -->
        <template slot="type" slot-scope="item">
          <span v-if="item == 1">单选</span>
          <span v-else-if="item == 2">多选</span>
          <span v-else-if="item == 3">判断</span>
        </template>
        <!-- 1.简单 2.一般 3.困难 -->
        <template slot="difficulty" slot-scope="item">
          <span v-if="item == 1">简单</span>
          <span v-else-if="item == 2">一般</span>
          <span v-else-if="item == 3">困难</span>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "60px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "题干",
    align:'center',
    dataIndex: "title"
  },
  {
    title: "类型",
    align:'center',
    dataIndex: "type",
    scopedSlots: { customRender: 'type' }
  },
  {
    title: "难度",
    align:'center',
    dataIndex: "difficulty",
    scopedSlots: { customRender: 'difficulty' }
  }
]
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      visible: false,
      columns,
      tableData: [],
      loading: false,
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      name: '',
      selectedRowKeys: []
    }
  },
  // 事件处理器
  methods: {
    // 打开弹窗
    open(){
      this.selectedRowKeys = []
      this.tableData = []
      this.getList()
      this.visible = true
    },
    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
      this.getList()
    },

    // 查询列表
    getList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/exam/library/section/win/list',
        params: {
          category: 3,
          title: this.name,
          current: this.pageNumber,
          size: this.pageSize,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.message)
        }
        setTimeout(()=>{
          this.loading = false
        },300)
      }).catch(err=>{
        setTimeout(()=>{
          this.loading = false
        },300)
      })
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // 保存
    onSave(){
      this.$emit('change', this.selectedRowKeys)
      this.visible = false
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.title{
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  .right{
    margin-left: 10px;
  }
}
.table {
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect{
    padding-left: 6px;
  }
}
</style>
