<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    // 检查第一页并返回object
    fun_verificPage_1(pageName){
      let obj = new Object()
      const data = this.formData
      const greater_zero = this.$regular['greater_zero']

      // 验证课程名称
      if(!data.courseName){
        return Promise.reject({msg:'请输入课程名称',pageName})
      }else{
        obj.courseName = data.courseName
      }

      // 关联偏好
      if(!data.courseLikeIdList.length){
        return Promise.reject({msg:'请关联课程偏好',pageName})
      }else{
        obj.courseLikeIdList = data.courseLikeIdList
      }

      // 移动端列表图
      if(!data.mobileListPicture){
        return Promise.reject({msg:'请上传移动端列表图',pageName})
      }else{
        obj.mobileListPicture = data.mobileListPicture
      }

      // 移动端详情图
      if(!data.mobileDetailPicture){
        return Promise.reject({msg:'请上传移动端详情图',pageName})
      }else{
        obj.mobileDetailPicture = data.mobileDetailPicture
      }

      // 移动端商品图
      if(!data.mobileChiefPicture){
        return Promise.reject({msg:'请上传移动端商品图',pageName})
      }else{
        obj.mobileChiefPicture = data.mobileChiefPicture
      }

      // PC端列表图
      if(!data.pcListPicture){
        return Promise.reject({msg:'请上传PC端列表图',pageName})
      }else{
        obj.pcListPicture = data.pcListPicture
      }

      // PC端详情图
      if(!data.pcDetailPicture){
        return Promise.reject({msg:'请上传PC端详情图',pageName})
      }else{
        obj.pcDetailPicture = data.pcDetailPicture
      }

      // 客服二维码
      obj.customerCode = data.customerCode

      // 课程简介
      if(!data.introduction){
        return Promise.reject({msg:'请输入课程简介',pageName})
      }else{
        obj.introduction = data.introduction
      }

      // 客服介绍
      obj.customerIntroduce = data.customerIntroduce

      // 总课时
      if(!data.classHour){
        return Promise.reject({msg:'请输入视频数量',pageName})
      }else if(!greater_zero.reg.test(data.classHour)){
        return Promise.reject({msg:'视频数量'+greater_zero.msg,pageName})
      }else{
        obj.classHour = data.classHour
      }

      // 必修视频数量
      if(!data.compulsClassHour){
        return Promise.reject({msg:'请输入视频数量',pageName})
      }else if(!greater_zero.reg.test(data.compulsClassHour)){
        return Promise.reject({msg:'视频数量'+greater_zero.msg,pageName})
      }else{
        obj.compulsClassHour = data.compulsClassHour
      }
      
      // 课程类别
      if(!data.courseCategoryId){
        return Promise.reject({msg:'请选择课程类别',pageName})
      }else{
        obj.courseCategoryId = data.courseCategoryId
      }

      // 商品分类
      obj.codeType = data.codeType

      // 商品编码
      // if(!data.productCode){
      //   return Promise.reject({msg:'请输入商品编码',pageName})
      // }else{
      //   obj.productCode = data.productCode
      // }

      // 授课老师
      if(!data.courseTeacherIdList || data.courseTeacherIdList.length == 0){
        return Promise.reject({msg:'请选择授课老师',pageName})
      }else{
        obj.courseTeacherIdList = data.courseTeacherIdList
      }

      // 相关证书
      obj.courseRelevantCertificate = data.courseRelevantCertificate.filter(item=>{
        return item.name!='' && item.picture!=''
      })

      // 移动端课程详情
      if(!data.mobileDetail){
        return Promise.reject({msg:'请输入移动端课程详情',pageName})
      }else{
        obj.mobileDetail = data.mobileDetail
      }

      // PC端课程详情
      if(!data.pcDetail){
        return Promise.reject({msg:'请输入PC端课程详情',pageName})
      }else{
        obj.pcDetail = data.pcDetail
      }

      // 移动端学员须知
      obj.mobileStudentInstructions = data.mobileStudentInstructions

      // PC端学员须知
      obj.pcStudentInstructions = data.pcStudentInstructions

      // 移动端报名指南
      // obj.mobileRegistrationGuide = data.mobileRegistrationGuide

      // PC端报名指南
      // obj.pcRegistrationGuide = data.pcRegistrationGuide

      return Promise.resolve(obj)
    },

    // 检查第二页并返回object
    fun_verificPage_2(pageName){
      // if(this.offline){ // 线下开课
      //   let obj = new Object()
      //   return Promise.resolve(obj)
      // }
      let obj = new Object()
      const data = this.formData

      // 课程目录设置
      obj.setCatalogue = data.setCatalogue

      // 课程目录
      // if(data.setCatalogue == 1 && (!data.courseCatalogueList || data.courseCatalogueList.length == 0)){
      //   return Promise.reject({msg:'请设置课程目录',pageName})
      // }else 
      if(data.setCatalogue == 1){
        // 课程证书
        if(data.setCourseCertificate == '1' && data.courseCertificateList.length > 0 && !!data.courseCertificateList[0].certifId){
          function fors(arr){
            arr.forEach((item)=>{
              data.courseCertificateList.forEach(items=>{
                if(item.uid == items.value){
                  item.courseCertifTempId = items.certifId
                }
              })
              if(item.childList && item.childList.length>0){
                fors(item.childList)
              }
            })
            return arr
          }
          obj.courseCatalogueList = fors(data.courseCatalogueList)
        }else if(data.setCourseCertificate == '1'){
          return Promise.reject({msg:'请添加课程证书设置规则',pageName:'特殊设置'})
        }else{
          obj.courseCatalogueList = data.courseCatalogueList
        }
      }

      // 免费试看设置
      obj.freeTrialType = data.freeTrialType

      function getTryChapter(){
        let list = []
        function fors(arr){
          arr.forEach((item)=>{
            if(item.childList && item.childList.length>0){
              fors(item.childList)
            }
            if(item.freeTrial == 1){
              list.push(item)
            }
          })
        }
        fors(data.courseCatalogueList)
        return list
      }
      if(data.freeTrialType == '3' && !(getTryChapter() && getTryChapter().length > 0)){ // 当选择免费试看，不允许不选试看章节
        return Promise.reject({msg:'请设置免费试看',pageName})
      }else if(data.freeTrialType == '2'){ // 免费试看分钟为第一章节
        let is = false
        function fors(arr){
          arr.forEach((item)=>{
            if(item.videoFileId && !is){
              item.freeTrial = 1
              is = true
            }
            if(item.childList && item.childList.length>0){
              fors(item.childList)
            }
          })
          return arr
        }
        data.courseCatalogueList = fors(data.courseCatalogueList)
      }

      // 免费试看分钟数
      obj.freeTrialMinute = data.freeTrialMinute

      // 课程观看设置 - 首次拖拽
      obj.firstWatchType = data.firstWatchType ? '1' : '0'

      // 课程观看设置 - 再次拖拽
      obj.againWatch = data.againWatch ? '1' : '0'

      // 课程观看设置 - 首次倍速
      obj.firstSpeedType = data.firstSpeedType ? '1' : '0'

      // 课程观看设置 - 再次倍速
      obj.againSpeed = data.againSpeed ? '1' : '0'

      // 课程观看设置 - 是否按目录顺序进行观看
      obj.watchByCatalogueOrder = data.watchByCatalogueOrder ? '1' : '0'

      // 课程观看设置 - 观看条件
      obj.watchLimitType = data.watchLimitType

      // 课程观看设置 - 观看条件限制
      obj.courseWatchLimitList = data.courseWatchLimitList

      return Promise.resolve(obj)
    },

    // 检查第三页并返回object
    fun_verificPage_3(pageName){
      let obj = new Object()
      let arr = []
      const data = this.formData
      const greater_zero = this.$regular['greater_zero']
      // 开班设置
      obj.setOpenClass = data.setOpenClass

      // 开班设置 - 需要设置开班时间
      arr = []
      if(this.offline){ // 线下开课
        if(data.offlineSameAddr=='1'){ // 同一地点
          if(!this.addressValue[0]){
            return Promise.reject({msg:'请选择培训场次地点',pageName})
          }else if(!this.ProvinceAddress){
            return Promise.reject({msg:'请输入培训场次详细地址',pageName})
          }else if(this.FixedPlaceTrainingList && !this.FixedPlaceTrainingList.length){
            return Promise.reject({msg:'请添加培训场次',pageName})
          }else{
            this.FixedPlaceTrainingList.forEach(item=>{
              item.type = 2
              item.province = this.addressValue[0]
              item.city = this.addressValue[1]
              item.district = this.addressValue[2]
              item.address = this.ProvinceAddress
              arr.push(item)
            })
          }
        }else if(data.offlineSameAddr=='0'){ // 不同地点
          if(this.NonFixedPlaceTrainingList && !this.NonFixedPlaceTrainingList.length){
            return Promise.reject({msg:'请添加培训场次',pageName})
          }
          this.NonFixedPlaceTrainingList.forEach(item=>{
            let addressValue = item.addressValue
            item.province = addressValue[0]
            item.city = addressValue[1]
            item.district = addressValue[2]
            item.type = 2
            arr.push(item)
          })
        }
        obj.setOpenClass = 1;
      }else{ // 线上开课
        data.courseOpenTimeList.forEach(item=>{
          item.type = 1
          arr.push(item)
        })
      }
      obj.courseOpenTimeList = arr

      // 培训场次地点
      obj.offlineSameAddr = data.offlineSameAddr

      // if(!this.offline){ // 线上课程
        // 课程有效期设置 - 固定期限/长期
        obj.validDayType = data.validDayType

        // 课程有效期设置 - 天数
        if(data.validDayType == '1'){
          if(!data.validDays){
            return Promise.reject({msg:'请输入课程有效期设置天数',pageName})
          }else if(!greater_zero.reg.test(data.validDays)){
            return Promise.reject({msg:'课程有效期设置天数'+greater_zero.msg,pageName})
          }else{
            obj.validDays = data.validDays
          }

          // 课程有效期设置 - 有效期开始计算节点
          if(data.validStartType == '0'){
            return Promise.reject({msg:'请选择有效期开始计算节点',pageName})
          }else{
            obj.validStartType = data.validStartType
          }

          // 课程有效期设置 - 开始做第n节小节自测起
          if(data.validStartType == '1'){
            if(!data.validStartSectionId){
              return Promise.reject({msg:'请输入课程有效期设置第几节小节自测起计算',pageName})
            }else{
              obj.validStartSectionId = data.validStartSectionId
            }
          }

          // 课程有效期设置 - 观看此章节视频开始算起
          if(data.validStartType == '5'){
            if(!data.validStartSectionId){
              return Promise.reject({msg:'请选择章节',pageName})
            }else{
              obj.validStartSectionId = data.validStartSectionId
            }
          }
        }

        // 课程延期设置
        obj.setDelay = data.setDelay

        // 课程延期设置 - 需要设置课程延期
        if(data.setDelay == '1'){
          arr = []
          data.courseDelayList.forEach((item,index)=>{
            item.sort = index+1
            arr.push(item)
          })
          obj.courseDelayList = arr
        }
      // }

      // 课程考试设置
      obj.setExam = data.setExam

      // 课程考试设置 - 需要考试
      if(data.setExam == '1'){
        if(!data.examCertifTempId){
          return Promise.reject({msg:'课程考试设置请选择需要的证书',pageName})
        }else{
          obj.examCertifTempId = data.examCertifTempId
        }
      }

      // 课程证书设置
      obj.setCourseCertificate = data.setCourseCertificate
      
      // 第二种获得课程证书的方式
      if(data.setCourseCertificate == '2'){
        if (!data.watchCount) {
          return Promise.reject({msg:'请输入数量',pageName})
        }else{
        obj.watchCount = data.watchCount
        }
        if (!data.courseCertifId) {
          return Promise.reject({msg:'请选择证书模板',pageName})
        }else{
        obj.courseCertifId = data.courseCertifId
        }

      }


      // if(!this.offline){ // 线上开课
        // 小节自测设置
        obj.setSectionTest = data.setSectionTest

        // 小节自测设置 - 有小节自测
        // if(data.setSectionTest == '1'){
          if(!data.sectionTestCorrectRate && data.sectionTestCorrectRate !== 0){
            return Promise.reject({msg:'小节自测设置请输入通过率',pageName})
          }else{
            obj.sectionTestCorrectRate = data.sectionTestCorrectRate
          }
        // }
      // }

      return Promise.resolve(obj)
    },

    // 检查第四页并返回object
    fun_verificPage_4(pageName){
      let obj = new Object()
      let arr = []
      const data = this.formData

      // 所属商品类别
      if(!data.productCategoryIdList || data.productCategoryIdList.length == 0){
        return Promise.reject({msg:'请选择所属商品类别',pageName})
      }else{
        obj.productCategoryIdList = data.productCategoryIdList
      }

      // 商品价格设置 - 市场价(原价)
      if(data.originalPrice== '' || data.originalPrice == 0){
        return Promise.reject({msg:'请输入商品市场价格',pageName})
      }else{
        obj.originalPrice = data.originalPrice
      }

      // 商品价格设置 - 市场价是否在终端展示
      obj.setOriginal = Number(data.setOriginal) ? '1' : '0'

      // 商品价格设置 - 优惠价
      if((data.couponPrice == '' || data.couponPrice == 0) && !this.offline && this.courseFree != 1){
        return Promise.reject({msg:'请输入商品优惠价格',pageName})
      }else if(!this.offline && this.courseFree != 1 && data.couponPrice <= 0){
        return Promise.reject({msg:'非免费课程类型，商品优惠价格不能为0',pageName})
      }else{
        obj.couponPrice = data.couponPrice
      }

      // 机构购买设置 - 机构购买人数限制
      obj.organizationPrice = data.organizationPrice

      // 机构购买设置 - 机构价
      if(data.numberPeople == '' && data.numberPeople != 0){
        return Promise.reject({msg:'请输入商品机构价格',pageName})
      }else{
        obj.numberPeople = data.numberPeople
      }

      // 机构购买设置 - 允许兑换券形式购买
      obj.exchange = data.exchange

      // 关联商品设置
      obj.setRelevantProduct = data.setRelevantProduct

      // 关联商品
      obj.relevantProduct = []
      if(data.setRelevantProduct == '1'){
        arr = []
        this.setRelevantProductList.forEach(item=>{
          arr.push({
            relevantProductId: item.productId,
            type: 1
          })
        })
        obj.relevantProduct = arr
      }

      // 赠品设置
      obj.setRelevantGift = data.setRelevantGift

      // 关联赠品
      if(data.setRelevantGift == '1'){
        arr = []
        this.setRelevantGiftList.forEach(item=>{
          arr.push({
            relevantProductId: item.productId,
            type: 2
          })
        })
        obj.relevantProduct = [...obj.relevantProduct, ...arr]
      }

      // 是否有购买限制条件
      obj.setBuyLimit = data.setBuyLimit

      // 购买限制条件 1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
      if(this.setBuyLimitType1 && this.setBuyLimitType2){
         obj.buyLimitType = 3
      }else if(this.setBuyLimitType1){
        obj.buyLimitType = 1
      }else if(this.setBuyLimitType2){
        obj.buyLimitType = 2
      }else{
        obj.buyLimitType = 0
      }

      // 在平台购买以下课程方可购买
      arr = []
      this.SelectCourseList.forEach(item=>{
        arr.push(item.courseId)
      })
      obj.buyLimitCourseList = arr

      // 用户需要完成身份认证后才可购买
      obj.userAuthentication = data.userAuthentication

      // 提交证明提示语
      obj.buyPromptContent = data.buyPromptContent

      // 商品上架设置
      obj.groundSet = data.groundSet

      // 商品上架设置 - 设置上架时间
      if(data.groundSet == '3' && !data.groundTime){
        return Promise.reject({msg:'请设置上架时间',pageName})
      }else{
        obj.groundTime = data.groundTime
      }

      return Promise.resolve(obj)
    },

    // 提交
    onSave(){
      this.loadingBtn = true
      Promise.all([
        // 验证 - 基础设置
        this.fun_verificPage_1('基础设置'),
        // 验证 - 视频管理
        // this.fun_verificPage_2('视频管理'),
        this.fun_verificPage_2('特殊设置'),
        // 验证 - 特殊设置
        this.fun_verificPage_3('特殊设置'),
        // 验证 - 购买设置
        this.fun_verificPage_4('购买设置'),
      ]).then(res=>{
        let obj = {}
        res.forEach(item=>{
          obj = {...obj, ...item}
        })
        obj.btnType = 2 // 按钮类型：1.暂存，2.提交
        if(this.$route.query.state == '2'){ // 编辑
          obj.courseId = this.$route.query.courseId
          return this.$ajax({
            url: '/hxclass-management/course/',
            method: 'put',
            params: obj
          })
        }else{ // 新增
          return this.$ajax({
            url: '/hxclass-management/course/',
            method: 'post',
            params: obj
          })
        }
      }).then(res=>{
        if(res.code == '200' && res.success){
          this.$message.success(res.msg)
          this.$router.go(-1)
        }else{
          this.$message.warning(res.message)
        }
        this.loadingBtn = false
      }).catch(err=>{
        if(err.pageName){
          this.$message.warning(err.pageName+'：'+err.msg)
        }else{
          console.error(err)
        }
        this.loadingBtn = false
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {},
  // 生命周期-实例挂载后调用
  mounted () {
    if(this.$route.query.type == 'true') {
      this.formData.courseCategoryId = 3
      this.offline = 1
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>
