<template>
  <a-modal
    v-model="visible"
    width="1000px"
    title="选择商品"
    :centered="true"
    :closable="false"
  >
    <div class="search-box">
      <a-select
        class="search-input"
        placeholder="请选择类型"
        v-model="categorytype"
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option
          v-for="item in cgoodList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.classify }}
        </a-select-option>
      </a-select>
      <a-input
        class="search-input"
        v-model="categoryname"
        placeholder="请输入名称"
      />
      <a-button class="search-btn" type="primary" @click="onSearch()"
        >搜索</a-button
      >
    </div>

    <div class="table-box">
      <a-table
        class="table-template one"
        :row-selection="{
          columnTitle: '选择',
          selectedRowKeys: selectedRowKey,
          onChange: select,
        }"
        :rowKey="(item) => item.productId"
        :columns="categorycolumns"
        :data-source="categoryData"
        :pagination="{
          total: ctotal,
          current: cpageNumber,
          pageSize: cpageSize,
          showTotal: (res) => `共${ctotal}条`,
        }"
        bordered
        @change="oncPage"
      >
        <template slot="num">
          商品名称
        </template>
        <template slot="productType" slot-scope="item">
          <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
          <span v-if="item==1">课程</span>
          <span v-else-if="item==2">图书</span>
          <span v-else-if="item==3">教具</span>
          <span v-else-if="item==4">模拟考试</span>
          <span v-else-if="item==5">电子照片</span>
          <span v-else-if="item==6">延期</span>
          <span v-else-if="item==7">补考</span>
          <span v-else-if="item==8">直播</span>
          <span v-else-if="item==9">其他</span>
          <span v-else>-</span>
        </template>
        <template slot="index" slot-scope="item, row, index">
          {{ (cpageNumber - 1) * cpageSize + index + 1 }}
        </template>
      </a-table>

      <a-table
        class="table-template two"
        :rowKey="(item) => item.productId"
        :columns="selectcolumns"
        :data-source="selectData"
        :pagination="{
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          pageSize: spageSize,
        }"
      >
        <template slot="num">
          当前已选中
          <span class="blueText">{{ selectData.length }}</span> 个商品
        </template>
        <template slot="operation" slot-scope="item, row">
          <span class="blueText" style="white-space: nowrap;" @click="deleteGoods(row)">清除</span>
        </template>
      </a-table>
    </div>

    <template slot="footer">
      <a-button type="primary" @click="categoryOk()">确认</a-button>
      <a-button @click="close()">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
const categorycolumns = [
  {
    title: "序号",
    align: "center",
    width:'70px',
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: '商品类型',
    align: "center",
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    align: "center",
    dataIndex: "productName",
    scopedSlots: {
      title: "num",
    },
  },
];
const selectcolumns = [
  {
    align: "center",
    dataIndex: "productName",
    colSpan: 2,
    maxWidth:'300px',
    scopedSlots: {
      customRender: "info",
      title: "num",
    },
  },
  {
    title: "操作",
    align: "center",
    colSpan: 0,
    width: 20,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      row: {},
      visible: false,
      categorytype: undefined,
      cgoodList: [],
      categorycolumns,
      categoryname: '',
      categoryData: [],
      selectcolumns,
      selectedRowKey: [],
      selectData: [],
      cpageNumber: 1,
      spageSize: 10,
      ctotal: 0,
      cpageSize: 10,
    };
  },
  // 事件处理器
  methods: {
    // 打开弹窗
    open(row){
      this.row = row
      this.visible = true
      this.selectedRowKey = row.selectedRowKey || []
      this.categoryData = []
      this.cpageNumber = 1
      this.spageSize = 10
      this.selectData = row.data || []
      this.ctotal = 0
      this.cpageSize = 10
      this.categoryname = ''
      if(row.type == 'setRelevantProduct'){
        this.categorytype = 1
        this.cgoodList = [
          { id: 1, classify: "课程" },
          { id: 2, classify: "图书" },
          { id: 3, classify: "教具" },
        ]
      }else{
        this.cgoodList = [
          { id: undefined, classify: "全部" },
          { id: 1, classify: "课程" },
          { id: 2, classify: "图书" },
          { id: 3, classify: "教具" },
          { id: 4, classify: "模拟考试" },
          // { id: 5, classify: "电子照片" },
          // { id: 6, classify: "延期" },
          // { id: 7, classify: "补考" },
          // { id: 8, classify: "直播" }
        ]
      }
      this.categoryFilling()
    },

    // 搜索
    onSearch(){
      this.categoryFilling()
    },

    // 页面改动
    select(selectedRowKey, e) {
      // return
      this.selectedRowKey = selectedRowKey;
      // this.selectData = [];
      // this.arry = e;.push(ary[j])
      let ary = this.categoryData;
      for (let index = 0; index < selectedRowKey.length; index++) {
        for (let j = 0; j < ary.length; j++) {
          if (ary[j].productId == selectedRowKey[index]) {
            this.selectData[index] = ary[j];
          }
          // console.log(ary[j].productId == selectedRowKey[index]);
          // console.log("外存是" + selectedRowKey[index]);
        }
      }
    },

    categoryFilling(e) {
      let noGround = null
      if(this.row.type != "setRelevantGift"){
        noGround = 1
      }
      this.category = true;
      this.$ajax({
        url: '/hxclass-management/product-archives/manage/select-win',
        params: {
          name: this.categoryname,
          type: this.categorytype,
          page: this.cpageNumber,
          size: this.cpageSize,
          noGround: noGround
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.ctotal = res.data.total;
          this.categoryData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    oncPage(e){
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling();
    },
    
    // 清除
    deleteGoods(e) {
      // console.log(e);
      // return;
      let nbr = this.selectData.indexOf(e);
      // console.log(nbr);
      this.selectData.splice(nbr, 1);
      this.selectedRowKey.splice(nbr, 1);
    },

    // 关闭
    close(){
      this.visible = false
    },

    // 保存
    categoryOk(){
      this.$emit('ok',{
        ...this.row,
        checked: this.selectedRowKey,
        data: this.selectData
      })
      this.close()
    }
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.search-input{
  width: 240px;
  margin-right: 5px;
}
/deep/.ant-table-selection-column,/deep/.ant-table-column-title{
  white-space: nowrap;
}
.table-box{
  display: flex;
  flex-direction: row;
  .one{
    margin-right: 15px;
    flex: 1;
  }
  .two{
    max-width: 370px;
  }
}
</style>
