var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":"1000px","title":"选择商品","centered":true,"closable":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"search-box"},[_c('a-select',{staticClass:"search-input",attrs:{"placeholder":"请选择类型"},model:{value:(_vm.categorytype),callback:function ($$v) {_vm.categorytype=$$v},expression:"categorytype"}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.cgoodList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.classify)+" ")])})],2),_c('a-input',{staticClass:"search-input",attrs:{"placeholder":"请输入名称"},model:{value:(_vm.categoryname),callback:function ($$v) {_vm.categoryname=$$v},expression:"categoryname"}}),_c('a-button',{staticClass:"search-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("搜索")])],1),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template one",attrs:{"row-selection":{
        columnTitle: '选择',
        selectedRowKeys: _vm.selectedRowKey,
        onChange: _vm.select,
      },"rowKey":function (item) { return item.productId; },"columns":_vm.categorycolumns,"data-source":_vm.categoryData,"pagination":{
        total: _vm.ctotal,
        current: _vm.cpageNumber,
        pageSize: _vm.cpageSize,
        showTotal: function (res) { return ("共" + _vm.ctotal + "条"); },
      },"bordered":""},on:{"change":_vm.oncPage},scopedSlots:_vm._u([{key:"productType",fn:function(item){return [(item==1)?_c('span',[_vm._v("课程")]):(item==2)?_c('span',[_vm._v("图书")]):(item==3)?_c('span',[_vm._v("教具")]):(item==4)?_c('span',[_vm._v("模拟考试")]):(item==5)?_c('span',[_vm._v("电子照片")]):(item==6)?_c('span',[_vm._v("延期")]):(item==7)?_c('span',[_vm._v("补考")]):(item==8)?_c('span',[_vm._v("直播")]):(item==9)?_c('span',[_vm._v("其他")]):_c('span',[_vm._v("-")])]}},{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.cpageNumber - 1) * _vm.cpageSize + index + 1)+" ")]}}])},[_c('template',{slot:"num"},[_vm._v(" 商品名称 ")])],2),_c('a-table',{staticClass:"table-template two",attrs:{"rowKey":function (item) { return item.productId; },"columns":_vm.selectcolumns,"data-source":_vm.selectData,"pagination":{
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        pageSize: _vm.spageSize,
      }},scopedSlots:_vm._u([{key:"operation",fn:function(item, row){return [_c('span',{staticClass:"blueText",staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.deleteGoods(row)}}},[_vm._v("清除")])]}}])},[_c('template',{slot:"num"},[_vm._v(" 当前已选中 "),_c('span',{staticClass:"blueText"},[_vm._v(_vm._s(_vm.selectData.length))]),_vm._v(" 个商品 ")])],2)],1),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.categoryOk()}}},[_vm._v("确认")]),_c('a-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }