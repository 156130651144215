<template>
  <div>
    <a-modal v-model="visible" :title="title" :width="width" :footer="null" :confirmLoading="confirmLoading">
      <!-- 新建课程目录 -->
      <template v-if="type=='newDirectory'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">目录名称：</a-col>
          <a-col span="20">
            <a-input placeholder="请输入目录名称" v-model="name"/>
          </a-col>
        </a-row>
      </template>

      <!-- 新建子课程目录 -->
      <template v-else-if="type=='newChildrenDirectory'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">当前选中：</a-col>
          <a-col span="20" style="line-height: 32px;">{{row.sectionName}}</a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">目录名称：</a-col>
          <a-col span="20">
            <a-input placeholder="请输入子目录名称" v-model="name"/>
          </a-col>
        </a-row>
      </template>

      <!-- 上传课程视频 -->
      <template v-else-if="type=='uploadVideo'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">视频：</a-col>
          <a-col span="20">
            <a-upload
              name="videoFileId"
              :showUploadList="false"
              :customRequest="customRequest"
            >
              <a-button>
                <template v-if="uploadLoading">
                  <a-icon type="loading" />
                  <span>上传中 {{(percent*100).toFixed(0)}}%</span>
                </template>
                <template v-else>
                  <a-icon type="upload" />
                  <span>选择视频文件</span>
                </template>
              </a-button>
            </a-upload>
            <p v-if="videoFileId">
              <span>上传成功：</span>
              <span>{{videoFileId}}</span>
            </p>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">授课老师：</a-col>
          <a-col span="20">
            <a-select
              style="width:100%;"
              mode="multiple"
              v-model="checked"
              :filterOption="filterInstructorList"
              placeholder="请选择授课老师(可多选)"
            >
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option :value="item.teacherId" v-for="item in InstructorList" :key="item.name">{{item.name}}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </template>

      <!-- 选择小节自测题目 -->
      <template v-else-if="type=='SectionTest'">
        <div style="text-align:right;" v-if="state != '3'">
          <a-button type="primary" @click="$refs.ModalSection.open()">选择题目</a-button>
        </div>
        
        <!-- 单选题 -->
        <h4 class="SectionTitle" :style="state == '3' ? 'margin-top:0;' : ''">单选题</h4>
        <template v-if="list.redioList && list.redioList.length">
          <div v-for="(item,index) in list.redioList" :key="item.libraryId" :style="index!=0?'margin-top:30px;':''">
            <a-row style="margin-bottom:10px;">
              <a-col span="20" style="font-weight: bold;font-size:16px;">
                <span>{{index+1}}、</span>
                <span>{{item.title}}</span>
              </a-col>
              <a-col span="4" style="text-align:right;" v-if="state != '3'">
                <a v-if="index!=0" @click="moveSection('redioList',1,index)">上移</a>
                <span v-if="index!=0"> | </span>
                <a v-if="index<list.redioList.length-1" @click="moveSection('redioList',2,index)">下移</a>
                <span v-if="index<list.redioList.length-1"> | </span>
                <a @click="removeSection('redioList',index)">删除</a>
              </a-col>
            </a-row>
            <a-row v-for="i in Math.ceil((item.optionList?item.optionList:[]).length / 2)" :key="'option1'+i">
              <a-col class="col" offset="1" span="11" v-if="item.optionList[(i-1)*2]">
                <span>{{ABCD[(i-1)*2]}}、</span>
                <span>{{item.optionList[(i-1)*2].name}}</span>
              </a-col>
              <a-col class="col" span="11" v-if="item.optionList[(i-1)*2+1]">
                <span>{{ABCD[(i-1)*2+1]}}、</span>
                <span>{{item.optionList[(i-1)*2+1].name}}</span>
              </a-col>
            </a-row>
          </div>
        </template>
        <a-empty v-else style="padding:50px;"/>
        
        <!-- 多选题 -->
        <h4 class="SectionTitle">多选题</h4>
        <template v-if="list.checkedList && list.checkedList.length">
          <div v-for="(item,index) in list.checkedList" :key="item.libraryId" :style="index!=0?'margin-top:30px;':''">
            <a-row style="margin-bottom:10px;">
              <a-col span="20" style="font-weight: bold;font-size:16px;">
                <span>{{(list.redioList?list.redioList.length:0)+index+1}}、</span>
                <span>{{item.title}}</span>
              </a-col>
              <a-col span="4" style="text-align:right;" v-if="state != '3'">
                <a v-if="index!=0" @click="moveSection('checkedList',1,index)">上移</a>
                <span v-if="index!=0"> | </span>
                <a v-if="index<list.checkedList.length-1" @click="moveSection('checkedList',2,index)">下移</a>
                <span v-if="index<list.checkedList.length-1"> | </span>
                <a @click="removeSection('checkedList',index)">删除</a>
              </a-col>
            </a-row>
            <a-row v-for="i in Math.ceil((item.optionList?item.optionList:[]).length / 2)" :key="'option2'+i">
              <a-col class="col" offset="1" span="11" v-if="item.optionList[(i-1)*2]">
                <span>{{ABCD[(i-1)*2]}}、</span>
                <span>{{item.optionList[(i-1)*2].name}}</span>
              </a-col>
              <a-col class="col" span="11" v-if="item.optionList[(i-1)*2+1]">
                <span>{{ABCD[(i-1)*2+1]}}、</span>
                <span>{{item.optionList[(i-1)*2+1].name}}</span>
              </a-col>
            </a-row>
          </div>
        </template>
        <a-empty v-else style="padding:50px;"/>
        
        <!-- 判断题 -->
        <h4 class="SectionTitle">判断题</h4>
        <template v-if="list.switchList && list.switchList.length">
          <div v-for="(item,index) in list.switchList" :key="item.libraryId" :style="index!=0?'margin-top:30px;':''">
            <a-row style="margin-bottom:10px;">
              <a-col span="20" style="font-weight: bold;font-size:16px;">
                <span>{{(list.redioList?list.redioList.length:0)+(list.checkedList?list.checkedList.length:0)+index+1}}、</span>
                <span>{{item.title}}</span>
              </a-col>
              <a-col span="4" style="text-align:right;" v-if="state != '3'">
                <a v-if="index!=0" @click="moveSection('switchList',1,index)">上移</a>
                <span v-if="index!=0"> | </span>
                <a v-if="index<list.switchList.length-1" @click="moveSection('switchList',2,index)">下移</a>
                <span v-if="index<list.switchList.length-1"> | </span>
                <a @click="removeSection('switchList',index)">删除</a>
              </a-col>
            </a-row>
            <a-row v-for="i in Math.ceil((item.optionList?item.optionList:[]).length / 2)" :key="'option3'+i">
              <a-col class="col" offset="1" span="11" v-if="item.optionList[(i-1)*2]">
                <span>{{ABCD[(i-1)*2]}}、</span>
                <span>{{item.optionList[(i-1)*2].name}}</span>
              </a-col>
              <a-col class="col" span="11" v-if="item.optionList[(i-1)*2+1]">
                <span>{{ABCD[(i-1)*2+1]}}、</span>
                <span>{{item.optionList[(i-1)*2+1].name}}</span>
              </a-col>
            </a-row>
          </div>
        </template>
        <a-empty v-else style="padding:50px;"/>
        <div class="ModalBtn" style="padding-top:50px;" v-if="state == '3'">
          <a-button type="primary" @click="visible=false">关闭</a-button>
        </div>
      </template>

      <!-- 添加章节 -->
      <template v-else-if="type=='SelectChapter'">
        <div>
          <div class="left"></div>
          <div class="right">
            <span>已选中</span>
            <span>{{checked.length}}</span>
            <span>个视频</span>
          </div>
        </div>
        <div>
          <a-tree
            @check="changeSelectChapter"
            :checkStrictly="true"
            :showIcon="false"
            :selectedKeys="checked"
            checkable
            :defaultExpandAll="true"
            :tree-data="funFilterVideo(list)"
            :replaceFields="{children:'childList', title:'sectionName', key:'uid' }"
          />
        </div>
      </template>

      <!-- 添加章节-课程有效期 -->
      <template v-else-if="type=='SelectChapterValidDay'">
        <div>
          <a-tree
            @select="changeSelectChapterVD"
            :checkStrictly="true"
            :showIcon="false"
            :selectedKeys="checkedVD"
            :defaultExpandAll="true"
            :defaultCheckedKeys="checkedVD"
            :tree-data="funFilterVideo(list)"
            :replaceFields="{children:'childList', title:'sectionName', key:'sectionId' }"
          />
        </div>
      </template>

      <!-- 选择考试 -->
      <template v-else-if="type=='SelectiveExam'">
        <div>
          <a-input v-model="name" placeholder="请输入考试名称" style="width:240px;margin-right:10px;"/>
          <a-button type="primary" @click="getExamList()">搜索</a-button>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: checked,
            onChange: onSelectChange
          }"
          class="table-template"
          :rowKey="item=>item.configId"
          :columns="columnsExam"
          :data-source="tableData"
          :loading="loading"
          @change="onPage"
          :pagination="{
            size: 'small',
            total:total,
            current:pageNumber,  
            defaultPageSize:pageSize, 
            showSizeChanger: true,
            showTotal: function(total, range){
            return `共${total}条`}} ">
          <template slot="index" slot-scope="e, row, i">
            <div style="text-align: center;">
              {{ (pageNumber - 1) * pageSize + i + 1 }}
            </div>
          </template>
        </a-table>
      </template>

      <!-- 选择证书 -->
      <template v-else-if="type=='SelectiveCertificate'">
        <div>
          <a-input v-model="name" placeholder="请输入证书名称" style="width:240px;margin-right:10px;"/>
          <a-button type="primary" @click="getCertificateList()">搜索</a-button>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: checked,
            onChange: onSelectChange
          }"
          class="table-template"
          :rowKey="item=>item.tempId"
          :columns="columnsCertificate"
          :data-source="tableData"
          :loading="loading"
          @change="onPage"
          :pagination="{
            size: 'small',
            total:total,
            current:pageNumber,  
            defaultPageSize:pageSize, 
            showSizeChanger: true,
            showTotal: function(total, range){
            return `共${total}条`}} ">
          <template slot="index" slot-scope="e, row, i">
            <div style="text-align: center;">
              {{ (pageNumber - 1) * pageSize + i + 1 }}
            </div>
          </template>
        </a-table>
      </template>

      <!-- 新增班级 -->
      <template v-else-if="type=='SelectOpenClass'">
        <a-row>
          <a-col span="4" style="text-align: right;line-height: 32px;">班级名称：</a-col>
          <a-col span="20">
            <a-input placeholder="请输入班级名称" :maxLength="10" v-model="name"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">开班时间：</a-col>
          <a-col span="20">
            <a-range-picker style="width:100%;" v-model="checked" valueFormat="YYYY-MM-DD" :placeholder="['开班日期','结班日期']"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="4" style="text-align: right;line-height: 32px;">班级名额：</a-col>
          <a-col span="20">
            <a-input-number v-model="total" placeholder="请输入班级名额" style="width:100%;"/>
          </a-col>
        </a-row>
      </template>

      <!-- 选择课程 -->
      <template v-else-if="type=='SelectCourse'">
        <!-- 条件搜索 -->
        <div>
          <a-tree-select style="width:240px;"
            allowClear
            :tree-data="InstructorList"
            v-model="percent"
            placeholder="请选择课程类别"
            :replaceFields="{
              children:'childList', title:'name', key:'courseCategoryId', value: 'courseCategoryId' 
            }"
            :treeDefaultExpandAll="true"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
          </a-tree-select>
          <a-input placeholder="请输入课程名称" style="width:240px;margin:0 10px;" v-model="name"/>
          <a-button type="primary" @click="getCourseList()">搜索</a-button>
        </div>

        <!-- 内容 -->
        <div class="courseBox">
          <div class="left">
            <a-table
              class="table-template"
              :row-selection="{
                columnTitle: '选择',
                selectedRowKeys: selectedRowKeys,
                onChange: selectCourse,
              }"
              :rowKey="(item) => item.courseId"
              :columns="courseColumns"
              :loading="loading"
              :data-source="tableData"
              @change="onPage"
              :pagination="{
                total: total,
                current: pageNumber,
                pageSize: pageSize,
                showTotal: (res) => `共${total}条`,
              }"
              bordered
            >
              <template slot="index" slot-scope="item, row, index">
                {{ (pageNumber - 1) * pageSize + index + 1 }}
              </template>
            </a-table>
          </div>
          <div class="right">
            <a-table
              class="table-template"
              :rowKey="(item) => item.courseId"
              :columns="selectcolumns"
              :data-source="checked"
              bordered
            >
              <template slot="num">
                当前已选中
                <span class="blueText">{{ checked.length }}</span> 个商品
              </template>
              <template slot="operation" slot-scope="item, row">
                <span class="blueText" style="white-space: nowrap;" @click="removeCourse(row)">清除</span>
              </template>
            </a-table>
          </div>
        </div>
      </template>

      <!-- 新增培训场次 -->
      <template v-else-if="type=='FixedPlaceTraining'">
        <a-row>
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">班级名称：</a-col>
          <a-col span="19">
            <a-input placeholder="请输入班级名称" :maxLength="10" v-model="name"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">场次时间：</a-col>
          <a-col span="19">
            <a-range-picker style="width:100%;" valueFormat="YYYY-MM-DD" v-model="checked"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">名额：</a-col>
          <a-col span="19">
            <a-input-number :min="0" style="width:100%;" placeholder="请输入名额" v-model="total"/>
          </a-col>
        </a-row>
      </template>

      <!-- 新增培训场次 - 不同地点 -->
      <template v-else-if="type=='NonFixedPlaceTraining'">
        <a-row>
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">班级名称：</a-col>
          <a-col span="19">
            <a-input placeholder="请输入班级名称" :maxLength="10" v-model="name"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">场次时间：</a-col>
          <a-col span="19">
            <a-range-picker style="width:100%;" valueFormat="YYYY-MM-DD" v-model="checked"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">名额：</a-col>
          <a-col span="19">
            <a-input-number :min="0" style="width:100%;" placeholder="请输入名额" v-model="total"/>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col class="required" span="5" style="text-align: right;line-height: 32px;">培训地点：</a-col>
          <a-col span="19">
            <Province ref="Province" style="width:100%;" @regionCity='FunProvince' :value="addressValue"></Province>
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col span="19" offset="5">
            <a-input placeholder="请输入详细地址" v-model="videoFileId"/>
          </a-col>
        </a-row>
      </template>

      <!-- 类型错误 -->
      <template v-else>"type"类型错误！</template>

      <div class="ModalBtn" v-if="state != '3'">
        <a-button :loading="uploadLoading" type="primary" @click="onSave()">确定</a-button>
        <a-button style="margin-left:40px;" @click="visible=false">取消</a-button>
      </div>
    </a-modal>

    <!-- 选择自测题目 -->
    <ModalSection ref="ModalSection" @change="changeModalSection"/>
  </div>
</template>

<script>
import ModalSection from './Modal_section.vue'
import Province from '@/components/provinceCity'
const columnsExam = [
  {
    title: "序号",
    width: "60px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }
  },
  {
    title: "考试名称",
    align:'center',
    dataIndex: "name"
  }
]
const columnsCertificate = [
  {
    title: "序号",
    width: "60px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "证书名称",
    align:'center',
    dataIndex: "certifName"
  }
]
const courseColumns = [
  {
    title: "序号",
    width: "70px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }
  },
  {
    title: "课程名称",
    align:'center',
    dataIndex: "courseName",
  },
]
const selectcolumns = [
  {
    align: "center",
    dataIndex: "courseName",
    colSpan: 2,
    maxWidth:'300px',
    scopedSlots: {
      customRender: "info",
      title: "num",
    },
  },
  {
    title: "操作",
    align: "center",
    colSpan: 0,
    width: 20,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: { ModalSection, Province },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      ABCD: ['A','B','C','D','E','F','G','H'],
      row: null, // 当前数据
      title: '未命名', // 标题
      width: '600px', // 宽度
      type: '*', // 弹窗类型
      visible: false,
      uploadLoading: false,
      columnsExam,
      columnsCertificate,
      courseColumns,
      selectcolumns,
      loading: false,

      // 会清的数据
      InstructorList: [], // 授课老师数组
      name: '',
      checked: [],
      checkedVD: [],
      videoFileId: '',
      provinceStr: '',
      percent: 0,
      uid: null,
      list: [],
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      selectedRowKeys: [],
      addressValue: [],
      confirmLoading: false,
      videoDuration: null,
      state: 1
    }
  },
  // 事件处理器
  methods: {
    // 选择课程章节
    changeSelectChapter(e){
      this.checked = e.checked
    },

    // 选择课程章节
    changeSelectChapterVD(e, {node}) {
      // console.log(e , this.funFilterVideo(this.list))
      this.checkedVD = e
      this.name = node.title
    },

    // 获取省市区编码
    FunProvince (data) {
      this.addressValue = data
    },

    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
      if(this.type == 'SelectiveExam'){
        this.getExamList()
      }else if(this.type == 'SelectiveCertificate'){
        this.getCertificateList()
      }else if(this.type == 'SelectCourse'){
        this.getCourseList()
      }
    },

    // 打开弹窗
    open(obj,row){
      // console.log(111, obj,row)
      // 重置数据
      this.name = ''
      this.checked = []
      this.checkedVD = []
      this.videoFileId = ''
      this.provinceStr = ''
      this.percent = 0
      this.list = []
      this.tableData = []
      this.total = 0
      this.selectedRowKeys = []
      this.pageNumber = 1
      this.pageSize = 10
      this.InstructorList = []
      this.addressValue = []
      this.row = row || {}
      this.list = obj.list || []
      this.title = obj.title || '未命名'
      this.width = obj.width || '600px'
      this.type = obj.type || '*'
      this.state = obj.state || '1'
      this.visible = true
      this.videoDuration = null
      this.confirmLoading = false
      this.arrImport = []

      // 请求数据
      if(obj.type == 'newDirectory'){
        if(obj.state == '2'){
          this.name = row.sectionName
        }
      }else if(obj.type == 'uploadVideo'){
        this.getInstructor()
        if(obj.state == '2'){
          let data = []
          row.teacherIdList.forEach(item=>{
            data.push(item.id)
          })
          this.checked = data
          this.videoFileId = row.videoFileId
        }
      }else if(obj.type == 'SectionTest'){
        if(obj.state == '2'){
          let arr = []
          this.arrImport = []
          if(row.questionList && row.questionList.length >= 0){
            row.questionList.forEach(item=>{
              if(item.libraryId != undefined){
                arr.push(item.libraryId)
              }
              if(item.Import == 1){
                this.arrImport.push(item)
              }
            })
            if(arr.length>0){
              this.checked = arr
              this.changeModalSection(arr)
            }else if(row.questionIdList && row.questionIdList.length > 0){
              this.checked = row.questionIdList
              this.changeModalSection(row.questionIdList)
            }else if(this.arrImport.length > 0){
              this.arrImport.forEach(item=>{
                if(item.type == 1){
                  if(!this.list.redioList){
                    this.list.redioList = []
                  }
                  this.list.redioList.push(item)
                }else if(item.type == 2){
                  if(!this.list.checkedList){
                    this.list.checkedList = []
                  }
                  this.list.checkedList.push(item)
                }else if(item.type == 3){
                  if(!this.list.switchList){
                    this.list.switchList = []
                  }
                  this.list.switchList.push(item)
                }
              })
            }
          }else if(row.questionIdList.length > 0 && row.questionIdList){
            this.checked = row.questionIdList
            this.changeModalSection(row.questionIdList)
          }
        }else if(obj.state == '3'){
          let arr = []
          this.arrImport = []
          if(row.questionList && row.questionList.length >= 0){
            row.questionList.forEach(item=>{
              if(item.libraryId != undefined){
                arr.push(item.libraryId)
              }
              if(item.Import == 1){
                this.arrImport.push(item)
              }
            })
            if(arr.length>0){
              this.checked = arr
              this.changeModalSection(arr)
            }else if(row.questionIdList && row.questionIdList.length > 0){
              this.checked = row.questionIdList
              this.changeModalSection(row.questionIdList)
            }else if(this.arrImport.length > 0){
              this.arrImport.forEach(item=>{
                if(item.type == 1){
                  if(!this.list.redioList){
                    this.list.redioList = []
                  }
                  this.list.redioList.push(item)
                }else if(item.type == 2){
                  if(!this.list.checkedList){
                    this.list.checkedList = []
                  }
                  this.list.checkedList.push(item)
                }else if(item.type == 3){
                  if(!this.list.switchList){
                    this.list.switchList = []
                  }
                  this.list.switchList.push(item)
                }
              })
            }
          }else if(row.questionIdList.length > 0 && row.questionIdList){
            this.checked = row.questionIdList
            this.changeModalSection(row.questionIdList)
          }
        }
      }else if(obj.type == 'SelectiveExam'){
        let arr = []
        obj.checked.forEach(item=>{
          arr.push(item.dataId)
        })
        this.checked = arr
        this.getExamList()
      }else if(obj.type == 'SelectiveCertificate'){
        let arr = []
        obj.checked.forEach(item=>{
          arr.push(item.dataId)
        })
        this.checked = arr
        this.getCertificateList()
      }else if(obj.type == 'SelectOpenClass' && obj.state == '2'){
        this.name = row.className
        this.checked = [row.startTime, row.endTime]
        this.total = row.number
      }else if(obj.type == 'SelectCourse'){
        this.percent = undefined
        this.getCategorySelect()
        this.getCourseList()
      }else if(obj.type == 'SelectChapter'){
        if(obj.checked && obj.checked.length > 0){
          let arr = []
          obj.checked.forEach(item=>{
            arr.push(item.uid)
          })
          this.checked = arr
        }
      }else if(obj.type == 'SelectChapterValidDay'){
        // console.log(obj)
        if(obj.checked && obj.checked.length > 0){
          this.checkedVD = obj.checked
        }
        // console.log(this.checkedVD)
      }else if(obj.type == 'FixedPlaceTraining' && obj.state == '2'){
        this.name = row.className
        this.checked = [row.startTime, row.endTime]
        this.total = row.number
      }else if(obj.type == 'NonFixedPlaceTraining' && obj.state == '2'){
        this.name = row.className
        this.checked = [row.startTime, row.endTime]
        this.total = row.number
        this.addressValue = row.addressValue
        this.videoFileId = row.address
        this.provinceStr = row.provinceTxt ? (row.provinceTxt + (row.cityTxt ? ('/' + row.cityTxt) + (row.districtTxt?( '/' + row.districtTxt):'') : '') ) : ''
      }
    },

    // 关闭弹窗
    close(){
      this.visible = false
    },

    // 保存
    onSave(){
      this.uploadLoading = true
      let obj = {
        uid: this.row.uid,
        name: this.name,
        checked: this.checked,
        checkedVD: this.checkedVD,
        videoFileId: this.videoFileId,
        videoDuration: this.videoDuration,
        total: this.total,
        state: this.state
      }
      if(this.type == 'SectionTest'){
        let arr = []
        let arr2 = []
        if(this.list.redioList && this.list.redioList.length > 0){
          this.list.redioList.forEach(item=>{
            item.category = 3
            arr.push(item.libraryId)
            arr2.push(item)
          })
        }
        if(this.list.checkedList && this.list.checkedList.length > 0){
          this.list.checkedList.forEach(item=>{
            item.category = 1
            arr.push(item.libraryId)
            arr2.push(item)
          })
        }
        if(this.list.switchList && this.list.switchList.length > 0){
          this.list.switchList.forEach(item=>{
            item.category = 1
            arr.push(item.libraryId)
            arr2.push(item)
          })
        }
        
        obj.checked = arr
        obj.checked2 = arr2
      }else if(this.type == 'uploadVideo' && this.videoDuration == null){
        this.$message.error('请上传视频')
        this.uploadLoading = false
        return false
      }else if(obj.type == 'SelectChapter'){
        this.uploadLoading = false
        return false
      }else if(obj.type == 'SelectChapterValidDay'){
        this.uploadLoading = false
        return false
      }else if(this.type == 'FixedPlaceTraining'){
        if(!this.name || !this.checked || !this.total){
          this.$message.warning('请输入必填项')
          this.uploadLoading = false
          return false
        }
      }else if(this.type == 'NonFixedPlaceTraining'){
        if(!this.name || !this.checked || !this.total || !this.addressValue || !this.videoFileId){
          this.$message.warning('请输入必填项')
          this.uploadLoading = false
          return false
        }
        obj.addressValue = this.addressValue
        obj.Province = this.$refs.Province.txt() ? this.$refs.Province.txt() : this.provinceStr
      }
      this.$emit('ok',{
        type: this.type,
        data: obj
      })
      setTimeout(()=>{
        this.uploadLoading = false
      },2000)
      this.close()
    },

    // 查询授课老师
    getInstructor(e){
      this.$ajax({
        url: '/hxclass-management/teacher/select',
        parame: {
          // name: e || ''
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.InstructorList = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 改写授课老师搜索条件
    filterInstructorList(inputValue, option){
      return option.key.indexOf(inputValue) != -1
    },

    // 重置文件上传 - 单文件
    customRequest(fileData){
      this.confirmLoading = true
      this.uploadLoading = true
      this.$upload_video({
        file: fileData.file,
        progress: (e)=>{
          this.$set(this, 'percent', e.percent)
        },
        success: (e)=>{
          this.$set(this, fileData.filename, e)
          this.getVideoDuration(e)
        }
      })
    },

    // 查询视频播放时长
    getVideoDuration(videoFileId){
      this.$ajax({
        url: '/hxclass-management/cos/get-video-duration',
        params: {
          fileId: videoFileId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.videoDuration = res.data
          this.uploadLoading = false
          this.confirmLoading = false
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 小节自测题目回调
    changeModalSection(arr){
      this.checked = [...this.checked, ...arr]
      this.$ajax({
        url: '/hxclass-management/exam/group/sub/preview/list',
        method:"post",
        params: {
          libraryIds: this.checked.join(',')
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.list = res.data
          if(this.arrImport.length > 0){
            this.arrImport.forEach(item=>{
              if(item.type == 1){
                if(!this.list.redioList){
                  this.list.redioList = []
                }
                this.list.redioList.push(item)
              }else if(item.type == 2){
                if(!this.list.checkedList){
                  this.list.checkedList = []
                }
                this.list.checkedList.push(item)
              }else if(item.type == 3){
                if(!this.list.switchList){
                  this.list.switchList = []
                }
                this.list.switchList.push(item)
              }
            })
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 删除小节自测选题
    removeSection(name,index){
      this.checked = this.checked.filter(res=>{
        return res != this.list[name][index].libraryId
      })
      this.list[name] = this.list[name].filter((res,i)=>{
        return i != index
      })
    },

    // 上下移动题目
    moveSection(name, move, i){
      let arr = this.list[name]
      let data = null
      if(move==1){
        data = arr[i-1]
        arr[i-1] = arr[i]
      }else if(move==2){
        data = arr[i+1]
        arr[i+1] = arr[i]
      }
      arr[i] = data
      this.list[name] = []
      this.list[name] = arr
    },

    // 筛选课程视频
    funFilterVideo(list){
      function fors(arr){
        arr.forEach((item,index)=>{
          if(item.childList && item.childList.length>0){
            item.childList = fors(item.childList)
          }
          if(!!!item.videoFileId){
            item.disabled = true
          }else{
            item.disabled = false
          }
        })
        return arr
      }
      return fors(list)
    },

    // 查询考试列表
    getExamList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/exam/config/list',
        params: {
          name: this.name,
          current: this.pageNumber,
          size: this.pageSize,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.message)
        }
        setTimeout(()=>{
          this.loading = false
        },300)
      }).catch(err=>{
        setTimeout(()=>{
          this.loading = false
        },300)
      })
    },

    // 查询证书列表
    getCertificateList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/certiftemp/getTempConfigList',
        params: {
          certifName: this.name,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.message)
        }
        setTimeout(()=>{
          this.loading = false
        },300)
      }).catch(err=>{
        setTimeout(()=>{
          this.loading = false
        },300)
      })
    },

    onSelectChange(e){
      this.checked = e
    },

    // 查询课程分类
    getCategorySelect(){
      this.$ajax({
        url: '/hxclass-management/course/category/select',
      }).then(res=>{
        if(res.code == 200 && res.success){
          res.data.forEach(item=>{
            if(item.childList.length > 0){
              item.disabled = true
            }
          })
          this.InstructorList = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 查询课程列表
    getCourseList(){
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/course/',
        params: {
          courseCategoryId: this.percent,
          courseName: this.name,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },

    // 选择课程名称
    selectCourse(keys){
      this.selectedRowKeys = keys;
      let ary = this.tableData;
      for (let index = 0; index < keys.length; index++) {
        for (let j = 0; j < ary.length; j++) {
          if (ary[j].courseId == keys[index]) {
            this.checked[index] = ary[j];
          }
        }
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    name(newVal,oldVal) {
      if(this.type == 'SelectOpenClass'|| this.type == 'FixedPlaceTraining' || this.type == 'NonFixedPlaceTraining') {
        if(newVal.length>=10) {
          this.$message.error('班期名称限制10个字内')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.videoFileId{
  color: #666;
  font-size: 14px;
  transition: all 0.2s;
  &:hover{
    color: @theme;
    text-decoration: underline;
  }
}
.SectionTitle{
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin: 50px 0 20px;
  &::before{
    content: ' ';
    width: 3px;
    height: 15px;
    background-color: @theme;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 6px;
    border-radius: 2px;
  }
}
.col{
  line-height: 32px;
}
/deep/.ant-table-selection-column,/deep/.ant-table-column-title{
  white-space: nowrap;
}
.courseBox{
  display: flex;
  flex-direction: row;
  .left{
    flex: 1;
  }
  .right{
    max-width: 400px;
    margin-left: 20px;
  }
}
.ModalBtn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.required::before{
  content: '*';
  color: red;
  margin-right: 2px;
}
</style>
